import React from 'react';
import { FaStar } from 'react-icons/fa'; // Import star icon

const Divider = () => {
  return (
    <div className="divider">
      <div className="line"></div>
      <div className="stars">
        <FaStar />
        <FaStar />
        <FaStar />
      </div>
      <div className="line"></div>
    </div>
  );
};

export default Divider;
