import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import pystata from "../../Assets/Projects/pystata.png";
import racingbarchart from "../../Assets/Projects/racingbarchart.jpg";
import slidingbarchart from "../../Assets/Projects/slidingbarchart.png";
import data_strategy from "../../Assets/Projects/data_strategy.png";
import datavis from "../../Assets/Projects/datavis.png";
import sentiment from "../../Assets/Projects/sentiment.png";



function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>


        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col xs={12} sm={12} md={6} lg={4} xl={4} className="project-card">
            <ProjectCard
              imgPath={racingbarchart}
              isBlog={false}
              title="Racing Barchart"
              description="Dash is an open source Python library built on top of Plotly.js, React and Flask, Dash ties modern UI elements like dropdowns, sliders, and graphs directly to your analytical Python code. It’s maintained by Plotly, another visualization library which helps building, scaling, and deploying data apps in Python."
              ghLink="https://github.com/gabegaz/racing_bar_charts"
              demoLink="https://medium.com/@gabegaz/racing-bar-charts-with-python-c4b0eb838291"
            />
          </Col>



          <Col xs={12} sm={12} md={6} lg={4} xl={4} className="project-card">
            <ProjectCard
              imgPath={slidingbarchart}
              isBlog={false}
              title="Sliding Bar Chart"
              description="If you, as a data scientist, produce data visualization you should be able to share them through a platform agnostic system to all of the concerned decision makers (usually non-technical people) so that they can interactively play with the graph"
              ghLink="https://github.com/gabegaz/bar_charts_with_slider"
              demoLink="https://medium.com/@gabegaz/charts-with-a-slider-using-dash-python-5fc848742b7e"              
            />
          </Col>

          <Col xs={12} sm={12} md={6} lg={4} xl={4} className="project-card">
            <ProjectCard
              imgPath={pystata}
              isBlog={false}
              title="PyStata"
              description="Stata provides two ways for Python and Stata to interact, and these mechanisms collectively are referred to as PyStata. For Stata and Python lovers, using Stata and Python in the same environment means using best of both worlds. Enter PyStata."
              demoLink="https://medium.com/@gabegaz/pystata-combining-stata-and-python-9149955e9615"
            />
          </Col>

          <Col xs={12} sm={12} md={6} lg={4} xl={4} className="project-card">
            <ProjectCard
              imgPath={data_strategy}
              isBlog={false}
              title="Data Strategy"
              description="Data has been a key strategic corporate asset. It helps improve decision making, understand markets and customers, provide intelligent services and produce smart products, and improve production process."
              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://medium.com/@gabegaz/why-data-strategy-1b71e3c80e52"
            />
          </Col>



          <Col xs={12} sm={12} md={6} lg={4} xl={4} className="project-card">
            <ProjectCard
              imgPath={datavis}
              isBlog={false}
              title="Data Visualization"
              description="This project will consist of a series of separate standalone data visulaization tasks. However, for a better conceptual flow and to learn basics first before jumping to more advanced tasks, it is advisable to go through them consecutively."
              ghLink="https://github.com/gabegaz/datavis"
              demoLink="https://gabegaz.github.io/datavis/about.html"
            />
          </Col>


          <Col xs={12} sm={12} md={6} lg={4} xl={4} className="project-card">
            <ProjectCard
              imgPath={sentiment}
              isBlog={false}
              title="Sentiment Analysis"
              description="Sentiment Analysis is a useful technique for gauging public opinion about diverse subjects, allowing companies and institutions to adjust tactics, enhance goods and services, personalize promotions, heighten user satisfaction, and monitor brand image. This project helps you."
              ghLink="https://github.com/gabegaz/sentiment"
              demoLink="https://gabegaz.github.io/sentiment/about.html"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;