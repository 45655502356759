import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';

const Skill2 = () => {
    const skills = [
    
       {
         title: 'Systems with Large Language Models',
         description: 'Design, develop and implement intelligent systems based on large language models to create solutions for a variety of business use cases.',   
         icon: faRobot,
       },
     ];

 return (
   <section className="skill-list">
     <ul className="skill-list-items">

       {skills.map((skill) => (
         <li key={skill.title} className="skill-item">

           <FontAwesomeIcon icon={skill.icon} className="skill-icon" />
           <div className="skill-details">
             <h4 className="skill-title">{skill.title}</h4>
             <p className="skill-subtitle">{skill.description}</p>
           </div>
           
         </li>

       ))}

     </ul>
   </section>
 );
};

export default Skill2;


