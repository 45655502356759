import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChess } from '@fortawesome/free-solid-svg-icons';

const Skill3 = () => {
    const skills = [
        {
            title: 'Data & AI Business Strategy',
            description: 'Provide strategic guidance on leveraging data & AI to achieve business objectives, identify opportunities, and assess potential impacts.',
            icon: faChess,
          },
     ];

 return (
   <section className="skill-list">
     <ul className="skill-list-items">

       {skills.map((skill) => (
         <li key={skill.title} className="skill-item">

           <FontAwesomeIcon icon={skill.icon} className="skill-icon" />
           <div className="skill-details">
             <h4 className="skill-title">{skill.title}</h4>
             <p className="skill-subtitle">{skill.description}</p>
           </div>
           
         </li>

       ))}

     </ul>
   </section>
 );
};

export default Skill3;


