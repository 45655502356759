import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myPhoto from "../../Assets/myphoto.jpeg";
import Tilt from "react-parallax-tilt";
import { FaLinkedinIn } from "react-icons/fa";

function Summary() {
  return (
    <Container fluid className="home-about-section" id="about" >
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className="home-about-description">
            <h1 style={{ fontSize: "1.8em" }}>

              ABOUT ME
            </h1>
            <p className="home-about-body">
            Driven to empower with data, ai and design. Leveraging my expertise in <b className="purple"> data science, 
            machine learning </b> and <b className="purple"> business model design</b>, I craft scalable solutions with data that solve real-world problems.
              <br />

              <br />
              My journey, from
            
               <b className="purple"> economics  </b>  to
               <b className="purple"> computer science  </b> to
               <b className="purple">  business school  </b>, 
                has shaped me into a passionate advocate for designing and development of human-centered <b className="purple">data products </b>.
              
              <br />
      
              
              <br />
              <b className="purple">Python </b> is my workhorse, and I use it to glue together many other <b className="purple"> opensource </b>  and proprietary technologies.
              
              <br />
              <br />

              I walk <b className="purple">solo <span role="img" aria-label="walking_man"> 🚶‍♂️</span></b>, 
              and love <b className="purple">coding <span role="img" aria-label="computer"> 💻</span></b>, 
              but don't let that fool you, I'm a <b className="purple">party </b> 
              animal 🕺 at home (...and at workplace? 🤔... I'm working from home.)
            </p>
            
          </Col>

          <Col xs={12} sm={12} md={12} lg={6} xl={6} className="myAvtar">
            <Tilt>
              <img src={myPhoto} className="photo img-fluid" alt="avatar" />
            </Tilt>

          <button className="linkedin-button">
            <a
              href="https://www.linkedin.com/in/gabegaz/"
              target="_blank"
              rel="noreferrer"
              className="icon-colour home-social-icons"
            >
              <FaLinkedinIn />
            </a>
          </button>
   
          </Col>

        </Row>
    </Container>
  );
}
export default Summary;
