import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
    return (
      <div className="text-size">
        <Typewriter
          options={{
            strings: [
              "Automating Data-Driven Decision Making",
              "Designing Data & AI Business Strategy",
              "Building Intelligent Systems with AI",
              "Designing Data & AI Based Business Models",
            ],
            autoStart: true,
            loop: true,
            deleteSpeed: 50,
          }}
        />
      </div>
    );
  }
  
  export default Type;
  
