import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

const Skill1 = () => {
 const skills = [

    {
        title: 'Data-Driven Decision Making',
        description: 'Automate extracting valuable insights from data through statistical & econometric analysis, and machine learning techniques to inform decision-making.',
        icon: faChartLine,
      },
 ];

 return (
   <section className="skill-list">
     <ul className="skill-list-items">

       {skills.map((skill) => (
         <li key={skill.title} className="skill-item">

           <FontAwesomeIcon icon={skill.icon} className="skill-icon" />
           <div className="skill-details">
             <h4 className="skill-title">{skill.title}</h4>
             <p className="skill-subtitle">{skill.description}</p>
           </div>
           
         </li>

       ))}

     </ul>
   </section>
 );
};

export default Skill1;


